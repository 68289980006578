import { LANDING_ROUTE_KEY } from "@/enums";
import { useProfileContext } from "@/providers/ProfileProvider";
import JobrightLogo from "@/ui/JobrightLogo";
import { useScroll } from "ahooks";
import { Button, ConfigProvider, Flex, Layout, Typography } from "antd";
import classNames from "classnames";
import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";

import { trackEvent } from "@/utils/trace";

import { LANDING_MENU_ITEM_ENUM, MOBILE_LANDING_MENU_ITEM_KEYS } from "@/constants";
import { LANDING_PATHNAME, MOBILE_APP, VISITOR_PATHNAME } from "@/constants/pathname";

import EnterpriseInvitePopup from "../EnterpriseInvitePopup";
import MobileDownloadBanner from "../MobileDownloadBanner";
import MobileDrawer from "../MobileDrawer";
import defaultStyles from "./index.module.less";

const MobileSignInModal = dynamic(() => import("@/components/Landing/MobileSignInModal"), {
  ssr: false,
});
const MobileSignUpModal = dynamic(() => import("@/components/Landing/MobileSignUpModal"), {
  ssr: false,
});

const { Header } = Layout;

type MobileLayoutHeaderProps = {
  className?: string;
  styles?: {
    readonly [key: string]: string;
  };
  isEnterprise?: boolean;
};

export const MOBILE_HEADER_HEIGHT = 60;
export const MOBILE_DOWNLOAD_BANNER_HEIGHT = 80;

const MobileLandingHeader: React.FC<MobileLayoutHeaderProps> = props => {
  const [signUpModalOpen, setSignUpModalOpen] = useState(false);
  const [signInModalOpen, setSignInModalOpen] = useState(false);
  const [showTop, setShowTop] = useState(false);
  const { profile } = useProfileContext();

  const router = useRouter();
  const scroll = useScroll(typeof document !== "undefined" ? document : undefined);
  const scrollHeight = useMemo(() => scroll?.top, [scroll?.top]);
  const styles = useMemo(() => ({ ...defaultStyles, ...props.styles }), [props.styles]);
  const [sideMenuDrawerOpen, setSideMenuDrawerOpen] = useState(false);

  useEffect(() => {
    if (router?.query?.login) {
      setSignUpModalOpen(true);
    }
  }, [router?.query?.login]);

  const onShowSignModal = useCallback(() => {
    setSignUpModalOpen(true);

    if (router.pathname === VISITOR_PATHNAME) {
      trackEvent("visitor_reco_list_joinnow", {
        scene: "job",
        platform: "mobile",
      });
    } else {
      trackEvent("mobile_joinnow_click");
    }
  }, [router?.pathname]);

  const onCloseSignModal = () => {
    setSignUpModalOpen(false);
  };

  const onShowSignInModal = () => {
    setSignInModalOpen(true);
  };

  const onCloseSignInModal = () => {
    setSignInModalOpen(false);
  };

  const renderMobileLoginContent = useCallback(() => {
    if (props?.isEnterprise) {
      return (
        <EnterpriseInvitePopup
          buttonProps={{
            children: <Typography.Text>Contact US</Typography.Text>,
            className: styles["enterprise-invite-button"],
            icon: <Image src={"/newimages/public/chatpop.svg"} width={16} height={16} alt=">" />,
          }}
          buttonText="Contact US"
        />
      );
    }

    return !profile?.result?.logined ? (
      <>
        <Button type="text" onClick={onShowSignInModal} className={styles["mobile-sign-in-button"]}>
          SIGN IN
        </Button>
        <Button
          type="primary"
          onClick={onShowSignModal}
          className={styles["mobile-sign-up-button"]}
        >
          JOIN NOW
        </Button>
      </>
    ) : null;
  }, [props?.isEnterprise, profile?.result?.logined, styles, onShowSignModal]);

  const onShowSideMenuDrawer = () => {
    setSideMenuDrawerOpen(true);
  };

  const onCloseSideMenuDrawer = useCallback(() => {
    setSideMenuDrawerOpen(false);
  }, []);

  return (
    <>
      <div
        className={classNames(styles.headerTop, props.className)}
        style={{
          backgroundColor: `rgba(255, 255, 255, ${
            scrollHeight && scrollHeight / MOBILE_HEADER_HEIGHT
          })`,
          top: showTop ? MOBILE_DOWNLOAD_BANNER_HEIGHT : 0,
        }}
      >
        <ConfigProvider
          theme={{
            components: {
              Layout: {
                headerBg: "transparent",
              },
            },
            token: {
              colorPrimaryHover: "#00F0A0",
              colorBgBase: "transparent",
            },
          }}
        >
          {!router.query.visit && <MobileDownloadBanner />}
          <Header
            className={classNames(styles["header"], styles["layout-header"], [styles["mobile"]])}
            style={{
              height: MOBILE_HEADER_HEIGHT,
            }}
          >
            {!scrollHeight || scrollHeight / MOBILE_HEADER_HEIGHT <= 1 ? (
              <Link href={{ pathname: LANDING_PATHNAME }}>
                <div className={styles.logo}>
                  <JobrightLogo theme="light" width={144} height={36} />
                </div>
              </Link>
            ) : (
              <Button
                type="text"
                className={styles["menu-button"]}
                onClick={onShowSideMenuDrawer}
                icon={
                  <Image src={"/newimages/public/menu.svg"} width={16} height={16} alt="menu" />
                }
              />
            )}

            <Flex className={styles.profile} gap={16} align="center">
              {renderMobileLoginContent()}
            </Flex>
          </Header>

          {signUpModalOpen && (
            <MobileSignUpModal
              open={signUpModalOpen}
              onCancel={onCloseSignModal}
              onClickSignIn={() => {
                setSignUpModalOpen(false);
                setSignInModalOpen(true);
              }}
            />
          )}
          {signInModalOpen && (
            <MobileSignInModal
              open={signInModalOpen}
              onCancel={onCloseSignInModal}
              onClickSignUp={() => {
                setSignInModalOpen(false);
                setSignUpModalOpen(true);
              }}
            />
          )}

          <MobileDrawer
            open={sideMenuDrawerOpen}
            onClose={onCloseSideMenuDrawer}
            items={MOBILE_LANDING_MENU_ITEM_KEYS.map(key => {
              if (key === LANDING_ROUTE_KEY.DIVIDER) {
                return { type: "divider" };
              }

              const item = LANDING_MENU_ITEM_ENUM[key];
              return {
                key: item?.key,
                label: <Link href={item.route}>{item.text}</Link>,
              };
            })}
          />
        </ConfigProvider>
      </div>
    </>
  );
};

export default MobileLandingHeader;
