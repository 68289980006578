import { useIntersectionObserver } from "@uidotdev/usehooks";
import { useUpdateEffect } from "ahooks";
import cs from "classnames";
import React, { MutableRefObject, useRef } from "react";

import styles from "./index.module.less";

const MobileLandingSubPageLayout = ({
  children,
  className,
  containerRef,
  containerClassName,
  threshold,
}: {
  children: React.ReactNode;
  className?: string;
  containerRef?: React.Ref<HTMLDivElement>;
  containerClassName: string;
  threshold?: number;
}) => {
  const [ref, entry] = useIntersectionObserver({
    threshold: threshold ?? 0.1,
    root: null,
  }) as [MutableRefObject<HTMLDivElement>, IntersectionObserverEntry | null];
  const ended = useRef(false);

  useUpdateEffect(() => {
    if (entry?.isIntersecting) {
      setTimeout(() => {
        ended.current = true;
      }, 2000);
    }
  }, [entry?.isIntersecting]);

  return (
    <section className={containerClassName} ref={ref}>
      <div
        className={cs(
          styles["landing-sub-page-layout"],
          !ended.current && styles["default"],
          entry?.isIntersecting && styles["started"],
          className
        )}
        ref={containerRef}
      >
        {children}
      </div>
    </section>
  );
};

export default MobileLandingSubPageLayout;
