import { Button, ButtonProps, ConfigProvider } from "antd";

const SimpleButton: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#000000",
          boxShadow: "none",
          boxShadowSecondary: "none",
          boxShadowTertiary: "none",
        },
      }}
    >
      <Button
        {...props}
        style={{
          border: "none",
          outline: "none",
          boxShadow: "none",
          transition: "none",
        }}
      >
        {children}
      </Button>
    </ConfigProvider>
  );
};

export default SimpleButton;
