import { useProfileContext } from "@/providers/ProfileProvider";
import { useReferralStore } from "@/store/referral";
import { useLocalStorageState } from "ahooks";
import Image from "next/image";
import { useRouter } from "next/router";
import React, { useEffect } from "react";

import { formatStorageKey, isLinkedInApp, trackEvent } from "@/utils";

import styles from "./index.module.less";

type MobileDownloadBannerProps = {
  onShow?: (value: boolean) => void;
  isFixed?: boolean;
};

const MobileDownloadBanner: React.FC<MobileDownloadBannerProps> = props => {
  const { onShow, isFixed = true } = props;
  const router = useRouter();

  const showBanner = useReferralStore(o => o.mobileInstallBannerOpen);
  const setShowBanner = useReferralStore(o => o.setMobileInstallBannerOpen);

  const [storedTime, setStoredTime] = useLocalStorageState<number>(
    formatStorageKey("mobile_show_download"),
    {
      defaultValue: 0,
    }
  );
  const { profile } = useProfileContext();

  useEffect(() => {
    const currentTime = Date.now();
    // 如果没有存储过 lastUpdated 或者时间超过24小时,展示弹窗
    if (
      !isLinkedInApp() &&
      (!storedTime || currentTime - Number(storedTime) > 24 * 60 * 60 * 1000)
    ) {
      setStoredTime(0);
      setShowBanner(true);
      onShow?.(true);
    } else {
      onShow?.(false);
    }
  }, []);

  useEffect(() => {
    if (showBanner && profile?.result)
      trackEvent("mobile_appdownload_banner_exposure", {
        usertype: profile?.result?.logined ? "logged-in" : "visitor",
      });
  }, [showBanner, profile]);

  const handleClose = () => {
    setStoredTime(Date.now());
    setShowBanner(false);
    onShow?.(false);
  };

  const handleToDownload = () => {
    trackEvent("mobile_appdownload_banner_click", {
      usertype: profile?.result?.logined ? "logged-in" : "visitor",
    });
    router.push("/mobile-app");
  };

  return (
    showBanner && (
      <div className={styles.topBanner} style={{ display: isFixed ? "flex" : "" }}>
        <div className={styles.bannerLeft}>
          <Image
            src={"/newimages/public/mobile_download/logo.svg"}
            alt="App Icon"
            width={60}
            height={60}
            className={styles.bannerIcon}
          />
          <div className={styles.bannerInfo}>
            <div className={styles.bannerTitle}>Jobright</div>
            <div className={styles.bannerSubtitle}>Your AI Job Search Copilot</div>
            <div className={styles.bannerStars}>
              <Image
                src={"/newimages/public/mobile_download/fs.svg"}
                alt="Start Icon"
                width={81}
                height={12}
              />
            </div>
          </div>
        </div>
        <div className={styles.bannerRight}>
          <button className={styles.installBtn} onClick={handleToDownload}>
            Install
          </button>
          <button className={styles.closeBtn} onClick={handleClose}>
            <Image
              src={"/newimages/public/mobile_download/close.svg"}
              alt="Close Icon"
              width={6}
              height={6}
            />
          </button>
        </div>
      </div>
    )
  );
};

export default MobileDownloadBanner;
