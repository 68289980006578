import { Drawer, Menu, MenuProps } from "antd";
import { ItemType, MenuItemType } from "antd/es/menu/hooks/useItems";
import Image from "next/image";
import React, { useMemo } from "react";

import styles from "./index.module.less";

type MobileSideMenuDrawerProps = {
  open: boolean;
  onClose: () => void;
  footerRenderer?: React.ReactNode;
  items: ItemType<MenuItemType>[];
  onItemClick?: MenuProps["onClick"];
};

const MobileDrawer: React.FC<MobileSideMenuDrawerProps> = ({
  open,
  onClose,
  footerRenderer,
  onItemClick,
  items,
}) => {
  const headerExtraContentRenderer = useMemo(() => {
    return <Image src={"/newimages/logo.svg"} width={80} height={20} alt="menu" />;
  }, []);

  const menuContentRenderer = useMemo(() => {
    return (
      <Menu
        onClick={onItemClick}
        className={styles["mobile-side-menu-drawer-menu"]}
        items={items}
      />
    );
  }, [items, onItemClick]);

  return (
    <Drawer
      placement="left"
      open={open}
      onClose={onClose}
      closeIcon={<Image src={"/newimages/public/close.svg"} width={16} height={16} alt="close" />}
      title={null}
      extra={headerExtraContentRenderer}
      footer={footerRenderer}
      width={320}
      id={styles["mobile-side-menu-drawer-id"]}
      className={styles["mobile-side-menu-drawer"]}
    >
      {menuContentRenderer}
    </Drawer>
  );
};

export default MobileDrawer;
